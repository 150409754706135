import React, { useEffect } from "react";
import { cvtTokenVar, useReactiveVar } from "@apollo";
import { navigate, ModalHelper } from "@utils";
import { i18n } from "@i18n";

const TravelResult = ({ location }) => {
    const currentLanguage = i18n.language;
    const cvtToken = useReactiveVar(cvtTokenVar);
    const {
        searchParams: {
            destination,
            checkIn,
            checkOut,
            guestCount,
            roomCount,
            latLng: { lat, lng },
        },
    } = location?.state;

    const buildQueryString = ({
        cvtToken,
        destination,
        lat,
        lng,
        checkIn,
        checkOut,
        roomCount,
        guestCount,
        language = "en",
    }) => {
        if (
            !cvtToken ||
            !destination ||
            !lat ||
            !lng ||
            !checkIn ||
            !checkOut ||
            !roomCount ||
            !guestCount
        ) {
            return null;
        }
        return `?cvt=${cvtToken}&destination=${destination}&lat=${lat}&lon=${lng}&check_in=${checkIn}&check_out=${checkOut}&rooms=${roomCount}&adults=${guestCount}&children=0&child_ages=&language=${language}`;
    };

    const queryString = buildQueryString({
        cvtToken,
        destination,
        lat,
        lng,
        checkIn,
        checkOut,
        roomCount,
        guestCount,
        language: currentLanguage,
    });

    const url = `https://travel.coinmiles.io/${queryString}`;

    const time: number = +process.env.GATSBY_TRAVEL_PAGE_REDIRECT_TIMEOUT || 1200000; // 20 min

    useEffect(() => {
        ModalHelper.open({
            modalType: "authOverlayModal",
            modalOptions: { disableBackdropClick: true },
        });
        setTimeout(() => ModalHelper.close(), 1500);

        return () => {
            if (ModalHelper) ModalHelper.close();
        };
    }, []);

    /* Redirects to Travel page after 'x' minutes
     * This will prevent the redirecting to Access' default page
     */
    useEffect(() => {
        const timeout = setTimeout(() => {
            navigate("/", { replace: true });
        }, time);
        return () => {
            timeout ? clearTimeout(timeout) : null;
            console.warn("-- Redirected");
        };
    }, []);

    return (
        <>
            <div className="main-container">
                {queryString ? (
                    <iframe frameBorder="0" className="iframe-container" src={url} />
                ) : null}
            </div>
            <style>{`
                .main-container {
                    height: calc(100vh - 16vh);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: none;
                }
                .iframe-container {
                    height: inherit;
                    width: 100%;
                }
            `}</style>
        </>
    );
};

export default TravelResult;
